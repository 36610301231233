import React, { useContext } from "react";
import "./SoftwareSkill.css";
import { skillsSection } from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";

export default function SoftwareSkill() {
  const { isDark } = useContext(StyleContext);
  return (
    <div>
      <div className="software-skills-main-div">
        <p
          className={
            isDark
              ? "dark-mode subTitle skills-text-subtitle"
              : "subTitle skills-text-subtitle"
          }
        >
          What techs do I use
        </p>
        <ul className="dev-icons">
          {skillsSection.softwareSkills.map((skills, i) => {
            return (
              <li
                key={i}
                className="software-skill-inline"
                name={skills.skillName}
              >
                {/* <i className={skills.fontAwesomeClassname}></i> */}
                <span
                  className="iconify"
                  data-icon={skills.fontAwesomeClassname}
                  data-inline="false"
                  id="skill-icon"
                ></span>
                <p>{skills.skillName}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
